<template>
  <h1 class="fade">Personal Projects</h1>
  <h2 class="fade">Do You Even</h2>
  <p class="fade">
    A while back me and my friends where looking for a simple, unopnionated,
    weight lift tracking app. We only needed it to do a few very basic things,
    but we couldnt find a free one that fit our needs. That's why I started
    developing my own app in Vue. It's still in development but can check out
    <a href="https://do-you-even-lift-tracker.web.app/" target="_blank"
      >the MVP</a
    >
  </p>
  <h2 class="fade">Games</h2>
  <p class="fade">
    In my spare time I like messing around with game engines. Here is a short
    demo of VR project I am currently working on. Where I am experimenting with
    procdural planet generation and VR:
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/JxDfRZc_L1U"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="fade">
    I have a lot more of these tech demo's and small games I have worked on, but
    nothing fancy. Its mostly me just exploring things that if find interesting
    in my free time.
  </p>

  <h2 class="fade">Codepen</h2>
  <p class="fade">
    I sometimes like to do these quick challanges to sharpen my css or design
    skills, the results I put on
    <a href="https://codepen.io/mubangadv" target="_blank"> my codepen</a>.
  </p>
  <h3 class="fade">Daily UI</h3>
  <p class="fade">
    <a href="https://collectui.com/challenges" target="_blank"
      >The Daily UI challange</a
    >
    are prompts to design the UI of a page. Instead of using a design program
    like Figma I usually go straight to css, and experiment with interesting CSS
    animations and interactions. Check out
    <a
      href="https://codepen.io/collection/nNvERe?cursor=ZD0xJm89MSZwPTEmdj0xNQ=="
      target="_blank"
      >my collection</a
    >
    of completed challanges.
  </p>
  <iframe
    class="fade"
    height="750"
    style="width: 650px"
    scrolling="no"
    title="Daily UI #009 | Music Player"
    src="https://codepen.io/mubangadv/embed/MNJGrr?default-tab=result"
    frameborder="no"
    loading="lazy"
    allowtransparency="true"
    allowfullscreen="true"
  >
    See the Pen
    <a href="https://codepen.io/mubangadv/pen/MNJGrr">
      Daily UI #009 | Music Player</a
    >
    by Mubanga (<a href="https://codepen.io/mubangadv">@mubangadv</a>) on
    <a href="https://codepen.io">CodePen</a>.
  </iframe>
  <h3 class="fade">100 days of CSS</h3>
  <p class="fade">
    <a href="https://100dayscss.com/about/" target="_blank">100 days of CSS</a>
    is a group of challanges, that require you to recreate a HTML/CSS snippet.
    My goal is always to don't look at their source code, and keep searching
    until I find my own solution. It's a little bit less creative than the Daily
    UI challanges, but I learn a lot more about CSS techniques I ofter was
    unawere of. Take a look at
    <a
      href="https://codepen.io/collection/DdRgZk?cursor=ZD0wJm89MCZwPTEmdj00"
      target="_blank"
      >my collection</a
    >.
  </p>
  <iframe
    class="fade"
    height="550"
    style="width: 500px"
    scrolling="no"
    title="87/100 Days of CSS"
    src="https://codepen.io/mubangadv/embed/eoNmNJ?default-tab=result"
    frameborder="no"
    loading="lazy"
    allowtransparency="true"
    allowfullscreen="true"
  >
    See the Pen
    <a href="https://codepen.io/mubangadv/pen/eoNmNJ"> 87/100 Days of CSS</a> by
    Mubanga (<a href="https://codepen.io/mubangadv">@mubangadv</a>) on
    <a href="https://codepen.io">CodePen</a>.
  </iframe>
  <p></p>
</template>
