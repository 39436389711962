<template>
  <div class="works-details" :class="{ open: open }">
    <div class="works-container">
      <i class="fa-solid fa-times close" @click="close()"></i>
      <div class="works-container-scroll">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.works-details {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 500;

  pointer-events: none;

  ::-webkit-scrollbar-thumb {
    background: rgba(100, 105, 115, 0.6);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(90, 95, 105, 0.7);
  }

  .works-container {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    bottom: 2rem;
    left: 2rem;
    max-width: 96rem;
    margin: 0 auto;

    padding: 0 0 0 3rem;

    border-radius: 2rem;

    background: white;

    z-index: 600;

    transform: scale(0);
    transition: transform 500ms cubic-bezier(0.36, 0, 0.66, -0.56);

    .works-container-scroll {
      height: 100%;
      max-height: 100%;
      mask-image: linear-gradient(
        to bottom,
        transparent 0%,
        black 10%,
        black 90%,
        transparent 100%
      );
      overflow-y: auto;
      overflow-x: visible;

      h1,
      p {
        color: var(--black-1);
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.5rem;
      }

      h4 {
        font-size: 1.25rem;
      }

      iframe {
        margin-top: -2rem;
        margin-bottom: 2rem;
      }

      .image {
        position: relative;
        max-width: 64rem;

        height: fit-content;

        img {
          max-width: 100%;
          max-height: 75vh;
        }
      }

      label {
        font-style: italic;
        margin-top: 1rem;
        margin-bottom: 2rem;
        appearance: none;
        display: block;
        color: gray;
      }

      p:last-of-type {
        margin-bottom: 15rem;
      }
    }

    .close {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      font-size: 2rem;
      cursor: pointer;

      z-index: 700;

      opacity: 0.6;
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: black;
    opacity: 0;

    transition: opacity 1000ms ease-in-out;
  }

  &.open {
    pointer-events: auto;

    .works-container {
      transform: scale(1);
      transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
    }
    &:after {
      opacity: 0.6;
    }

    .fade {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  props: {
    open: Boolean,
    close: Function,
  },
};
</script>
