<template>
  <h1 class="fade">Works</h1>
  <div class="works-cards">
    <div class="fade">
      <div class="card" @click="open('realtech')">
        <i class="fa-solid fa-house-laptop fade"></i>
        <h2 class="fade">Realtech/maklr</h2>
      </div>
    </div>

    <div class="fade">
      <div class="card" @click="open('master')">
        <i class="fa-solid fa-graduation-cap fade"></i>
        <h2 class="fade">Masters Degree</h2>
      </div>
    </div>

    <div class="fade">
      <div class="card" @click="open('hobby')">
        <i class="fa-regular fa-heart fade"></i>
        <h2 class="fade">Personal/Hobby</h2>
      </div>
    </div>

    <div class="fade">
      <div class="card" @click="open('cv')">
        <i class="fa-regular fa-file fade"></i>
        <h2 class="fade">Curriculum Vitae</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: Function,
  },
};
</script>

<style scoped lang="scss">
.works-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  padding-right: 1rem;

  .card {
    text-align: center;
    max-width: 40rem;
    padding: 2rem;
    border-radius: 1rem;

    margin-bottom: 3rem;

    background: white;
    color: #373a44;

    transition: transform 200ms ease-in-out;

    transform: scale(1);

    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 2rem 2rem -2rem rgba(10, 0, 50, 0.5),
        0.5rem 0.5rem 1rem -2rem rgba(10, 0, 50, 0.4);
      opacity: 0.5;
      transition: opacity 200ms ease-in-out;
    }

    &:hover {
      transform: scale(1.025);

      // &::after {
      //   opacity: 1;
      // }
    }
    i {
      font-size: 4rem;
      margin-bottom: 1.5rem;
      color: #53b0e7;

      &.fa-graduation-cap {
        color: #7553ad;
      }

      &.fa-heart {
        color: #e77553;
      }

      &.fa-file {
        color: #74b535;
      }
    }
    h2 {
      margin: 0;
      color: var(--black-3);
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 92rem) {
  .works-cards {
    grid-template-columns: 1fr;
    .card {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      padding: 1.5rem;
      i {
        font-size: 3rem;
        margin: 0;
        width: 4rem;
        margin-right: 0.75rem;
      }
      h2 {
        font-size: 2vw;
      }
    }
  }
}
</style>
