<template>
  <div
    :id="id"
    class="section"
    :class="{ visable: visable, 'not-visable': !visable }"
  >
    <div class="section-container">
      <div class="spacer"></div>
      <div class="content-container">
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "snap-section",
  props: {
    observer: IntersectionObserver,
    id: String,
    visable: Boolean,
  },
  mounted() {
    this.observer.observe(this.$el);
  },
};
</script>

<style lang="scss">
.section {
  scroll-snap-align: start;

  position: sticky;

  top: 0;

  height: 100vh;
  width: 100%;
  padding: 0 4rem;
  box-sizing: border-box;
  overflow: hidden;

  background-size: cover;
  background-position: 50% 80%;
  color: white;

  &#intro {
    background-image: url("~@/assets/backgrounds/intro.webp");
  }

  &#about {
    background-image: url("~@/assets/backgrounds/about.webp");
  }

  &#strengths {
    background-image: url("~@/assets/backgrounds/strengths.webp");
  }

  &#works {
    background-image: url("~@/assets/backgrounds/works.webp");
  }

  &#contact {
    background-image: url("~@/assets/backgrounds/contact.webp");
  }

  .section-container {
    display: flex;
    align-items: center;
    height: 100%;

    max-width: 96rem;
    margin: 0 auto;
    padding: 1rem;

    .content-container {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;

      max-height: 100%;
      mask-image: linear-gradient(
        to bottom,
        transparent 0%,
        black 10%,
        black 90%,
        transparent 100%
      );
      overflow-y: auto;
      overflow-x: visible;
    }

    .content {
      padding: 10vh 1rem;
    }

    > * {
      flex-basis: 50%;
    }
  }

  a {
    color: white;
  }
}

#about,
#works {
  .content-container {
    order: -1;
  }
}

.fade {
  opacity: 0;
  transform: translateY(4rem);
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition-delay: $i * 0.1s;
    }
  }
}

.visable {
  .fade {
    transform: translateY(0rem);
    opacity: 1;
  }
}

// .not-visable {
//   // animation: fade-in 0.5s forwards reverse ease-in-out;
// }
</style>
