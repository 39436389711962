<template>
  <h1 class="fade">Masters Degree</h1>
  <h2 class="fade">Graduation: Wheretrip</h2>
  <p class="fade">
    I graduated for a startup called WhereTrip. WhereTrip wanted to connect
    several APIs to find cheap vacations, in themes, but without specific
    destinations or dates. I did User Research and developed a subsequent design
    system.
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/NOCBCkypOJI"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <h2 class="fade">Exploring Interactions</h2>
  <p class="fade">
    For a course during my master, I was tasked with observe interactions
    between a group of people, identify a problem, and come up with a novel
    solution. My group was a team of people working together, the problem I
    found was unbalanced communication (some people are more comfortable voicing
    their opinion than others). After many interactive prototypes, I came up
    with the following solution.
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/v7Rv8B1UowM"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <h2 class="fade">JMP: Heijmerink Wagemakers</h2>
  <p class="fade">
    For my Joint Master Project, where 2 students from each Industrial Design
    Master (Design For Interaction, Integrated Product Design, and Strategic
    Product Design). We did a project for Heijmerink Wagemakers, known for their
    FietsHangaar. They wanted a new product in the bike parking space. Together
    with a dozen or so less developed ideas, this is what we came up with.
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/X8BCj5aSAw4"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <h2 class="fade">UXAD: Sony voice recorder</h2>
  <p class="fade">
    For the course Usability and Exeperience Evaluation and Redesign, me and
    four other students were tasked to, evaluate and redesign a Sony voice
    recorder.
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/nam5lVGF6NA"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <p></p>
</template>
