<template>
  <div class="menu">
    <div class="background"></div>
    <div
      class="item"
      :class="{ active: isInViewport('intro') }"
      @click="scroll(0)"
    >
      <div class="name">HOME</div>
      <div class="circle"></div>
    </div>

    <div
      class="item"
      :class="{ active: isInViewport('about') }"
      @click="scroll(1)"
    >
      <div class="name">ABOUT</div>
      <div class="circle"></div>
    </div>

    <div
      class="item"
      :class="{ active: isInViewport('strengths') }"
      @click="scroll(2)"
    >
      <div class="name">STRENGTHS</div>
      <div class="circle"></div>
    </div>

    <div
      class="item"
      :class="{ active: isInViewport('works') }"
      @click="scroll(3)"
    >
      <div class="name">WORKS</div>
      <div class="circle"></div>
    </div>

    <div
      class="item"
      :class="{ active: isInViewport('contact') }"
      @click="scroll(4)"
    >
      <div class="name">CONTACT</div>
      <div class="circle"></div>
    </div>
  </div>
  <div id="snap" class="snap">
    <snap-section id="intro" :observer="observer" :visable="sections.intro">
      <intro> </intro>
    </snap-section>

    <snap-section id="about" :observer="observer" :visable="sections.about">
      <about></about>
    </snap-section>

    <snap-section
      id="strengths"
      :observer="observer"
      :visable="sections.strengths"
    >
      <strengths></strengths>
    </snap-section>

    <snap-section id="works" :observer="observer" :visable="sections.works">
      <works :open="openWorksDetails"></works>
    </snap-section>

    <snap-section id="contact" :observer="observer" :visable="sections.contact">
      <contact></contact>
    </snap-section>
  </div>

  <works-details :open="worksDetails !== null" :close="closeWorksDetails">
    <realtech v-if="worksDetails == 'realtech'"></realtech>
    <master v-if="worksDetails == 'master'"></master>
    <hobby v-if="worksDetails == 'hobby'"></hobby>
    <cv v-if="worksDetails == 'cv'"></cv>
  </works-details>
</template>

<script>
// @ is an alias to /src
import SnapSection from "@/components/SnapSection.vue";
import WorksDetails from "@/components/WorksDetails.vue";

import Intro from "./home-sections/Intro.vue";
import About from "./home-sections/About.vue";
import Strengths from "./home-sections/Strengths.vue";
import Works from "./home-sections/Works.vue";
import Contact from "./home-sections/Contact.vue";

import Realtech from "./home-sections/works/Realtech.vue";
import Master from "./home-sections/works/Master.vue";
import Hobby from "./home-sections/works/Hobby.vue";
import Cv from "./home-sections/works/Cv.vue";

export default {
  name: "Home",
  components: {
    SnapSection,
    WorksDetails,
    Intro,
    About,
    Strengths,
    Works,
    Contact,
    Realtech,
    Master,
    Hobby,
    Cv,
  },
  data() {
    return {
      sections: {
        intro: false,
        about: false,
        strengths: false,
        work: false,
        contact: false,
      },
      worksDetails: null,
      observer: null,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onSectionObserved, {
      root: this.$el,
      threshold: 1.0,
    });
  },
  methods: {
    onSectionObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        console.log(target, this.sections.intro);
        if (!this.sections[target.id] === undefined) return;
        this.sections[target.id] = isIntersecting;
      });
    },
    scroll(snapNumber) {
      let height = window.innerHeight;
      document
        .getElementById("snap")
        .scrollTo({ top: height * snapNumber, behavior: "smooth" });
    },
    isInViewport(id) {
      let element = document.getElementById(id);
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    openWorksDetails(val) {
      this.worksDetails = val;
    },
    closeWorksDetails() {
      this.worksDetails = null;
    },
  },
};
</script>

<style scoped lang="scss">
.snap {
  overflow: scroll;
  height: 100vh;
  scroll-snap-stop: normal;
  scroll-snap-type: y mandatory;

  overflow-x: hidden;

  &::-webkit-scrollbar-thumb {
    background: rgba(160, 165, 170, 1);
    border: 1px solid white;
  }
}

.menu {
  position: fixed;
  right: 1rem;
  color: white;
  z-index: 100;
  font-size: 1.5rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .item {
    position: relative;
    opacity: 0.6;
    transition: opacity 300ms ease-in-out;
    padding: 0.5rem;

    .name {
      line-height: 1.7rem;
      font-weight: 600;
      position: absolute;
      right: 3rem;
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      padding-bottom: 1rem;
    }

    .circle {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      border: 4px solid white;
      border-radius: 50%;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;

        border-radius: 50%;

        border: 4px solid white;
        background: white;
        opacity: 0;

        transition: opacity 200ms ease-in-out;
      }
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
      .circle {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .active {
    .circle {
      &:after {
        opacity: 1;
      }
    }
  }

  .background {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: black;

    opacity: 0;

    transition: opacity 400ms ease-in-out;

    pointer-events: none;
  }

  &:hover {
    .name {
      opacity: 1;
    }

    .item {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .background {
      opacity: 0.6;
    }
  }
}
</style>
