<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: Metropolis;
  src: url("~@/assets/Metropolis/Metropolis-Black.otf");
  font-weight: 700;
}

@font-face {
  font-family: Metropolis;
  src: url("~@/assets/Metropolis/Metropolis-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Metropolis;
  src: url("~@/assets/Metropolis/Metropolis-Light.otf");
  font-weight: 300;
}

:root {
  --black-1: #0d1926;
  --black-2: #243242;
  --black-3: #384553;
  --black-4: #4a5663;
  --black-5: #5a6571;
}

html,
body {
  margin: 0;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-track-piece-end {
  background: transparent url("~@/assets/transparent.png") repeat-y;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(240, 245, 255, 0.2);
  transition: color 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(240, 245, 255, 0.5);
  transition: color 0.2s ease;
}

::-webkit-scrollbar-corner {
  width: 0px;
  height: 0px;
}

#app {
  font-family: Metropolis, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  letter-spacing: 0.05em;
}

h1 {
  font-size: 8rem;
  margin: 0;
  margin-bottom: 0.25em;
}

p {
  font-size: 1.25rem;
  line-height: 1.5em;

  margin-bottom: 2em;

  max-width: 48rem;

  &.big {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 96rem) {
  h1.resize {
    font-size: 7vw;
  }
}
</style>
