<template>
  <h1 class="fade resize">About me</h1>
  <p class="fade">
    I like to make things and learn new skills along the way. Some of my recent
    endeavors got me into making games, painting mini-figures, and 3D modeling.
  </p>
  <p class="fade">
    I like to stay active too, I often go on runs, lift weights, boulder and in
    the winter I love going on snowboarding.
  </p>
  <p class="fade">
    I was born in Zambia, that's where I get my middle name, Mubanga, from.
  </p>
</template>
