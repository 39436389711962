<template>
  <h1 class="fade">Realtech</h1>
  <p class="fade">
    I have been working for Realtech since July 2018. I was Realtech&#39;s first
    employee, and have been involved with many of the companies activities
    throughout the years, such as their brand identity, user experience, and
    product development.
  </p>
  <p class="fade">
    Realtech is a software startup that aims to change the way newly constructed
    houses are being sold in the Netherlands. We believe that the process can be
    made more pleasant, efficient and profitable, trough the means of
    digitization, for all parties involved.
  </p>
  <div class="fade image">
    <img
      src="/images/maklr responsive.png"
      alt="The maklr. product on different devices"
    />
  </div>
  <label class="fade">The maklr. product on different devices</label>
  <p class="fade">
    In 2021 it was decided to gradually stop using the name Realtech as a brand
    and instead start using our main product&#39;s name; maklr. (a working title
    that I came up with that stuck around) as the name for our product suite and
    eventually the company.
  </p>
  <h2 class="fade" id="brick-design-system-">BRICK (Design System)</h2>
  <p class="fade">
    In order to quickly prototype, build, iterate new products in a cohesive
    brand identity, it was decided early on to make use of a custom design
    system; BRICK.
  </p>
  <p class="fade">
    BRICK is a set of style guides combined with an Angular component library,
    that allows our developers to use these components like Lego blocks and
    build consistent looking pages. It saves a lot of time because complex
    components, like the data table, date picker, and address input, are reused.
    And if something changes in the styling or internal functionality of a
    component, it updates trough out all of our products (if desired) without
    developers having to go trough every page and make changes.
  </p>
  <div class="fade image">
    <img
      src="/images/Components.jpg"
      alt="Several Brick components printed and cutout, for a user design session"
    />
  </div>
  <label class="fade"
    >Several Brick components printed and cutout, for a user co-design
    session</label
  >
  <h3 class="fade" id="my-role">My Role</h3>
  <p class="fade">
    For the most part I am (unfortunately) the sole developer of brick, although
    I really enjoy working on it, I always hoped to get more people on board, to
    bounce ideas off and share the work. Off course I am able to discuss design
    and UX issues with our product owner, and technical issues with our other
    developers. That being said it gave me the chance to work on some really
    interesting issues. I will highlight a few of those, but there are many more
    I could talk about.
  </p>
  <h4 class="fade" id="user-research">User Research</h4>
  <p class="fade">
    I was involved with the user research early on. We have two users groups,
    home buyers and real estate professionals. We (me and our PO) did a lot of
    sessions, interviews and prototype tests with both groups trough out the
    development of all our products, which lead to a set of design qualities we
    want our brand to envision to both groups: Transparent (Clear), Handy (Easy)
    and Trustworthy (Dependable). These are the basis of our style guide as well
    as the design of the components of the design system.
  </p>
  <div class="fade image">
    <img
      src="/images/User Research.jpg"
      alt="Results of user sessions to determine our products design qualities"
    />
  </div>
  <label class="fade"
    >Results of user sessions to determine our products design qualities</label
  >
  <h4 class="fade" id="documentation">Documentation</h4>
  <p class="fade">
    To be usable by our developers we have thorough documentation, describing
    when and how to use each component. With examples, that show actual working
    components next their (template/HTML) code.
  </p>
  <div class="fade image">
    <img src="/images/brick docs.gif" alt="Example of brick documentation" />
  </div>
  <label class="fade">Example of the Brick documentation</label>
  <p class="fade">
    I write the documentation in a markdown file, and wrote a script that
    processes the file in to an Angular page, this includes creating navigation
    and tabs, extracting (template/HTML) code examples and shows them as live
    components, grabbing and displaying underlying a TypeScript if necessary
    (for when the HTML needs TypeScript data or methods to function).
  </p>
  <h4 class="fade" id="theming">Theming</h4>
  <p class="fade">
    BRICK is made to be theme-able, this allows us to offer our products as
    white label; replacing our logos, brand colors, and fonts, with those of our
    client. It&#39;s even possible to change properties like sizes, shadows, and
    corner rounding if desired.
  </p>
  <div class="fade image">
    <img
      src="/images/brick themes.gif"
      alt="Showing some of the basic abilities of our theming"
    />
  </div>
  <label class="fade">Showing some of the basic abilities of our theming</label>
  <p class="fade">
    Most existing theming solution like Google&#39;s Material would add a lot
    bloat to our package, and require a lot of wrangling to loose the
    &quot;Google look&quot; and add our own brand identity, let alone offer our
    products as a white label.
  </p>
  <p class="fade">
    Our senior developer and me developed a novel way to theme an Angular
    library using CSS variables, of which I am quite proud as it has worked
    quite well for us and we did not see this solution before. CSS variables
    definitions do not work out of the box with Angular so we had to figure out
    how to get them from our NPM package, and how to allow client themes to
    override our main theme.
  </p>
  <p class="fade">
    I set up the theme variables in a cascading way, for example it is possible
    to change the font for the whole theme, or just the headers or just the
    headers of cards. This way we could go as detailed or broad as we want,
    depending on the clients wishes. I kept our default theme pretty generic.
    This has served us well because for most projects, we only have to change
    the logo and main color, and the fonts.
  </p>
  <p class="fade">
    On the subject of colors, I wrote a script that will take a primary color
    and programatically creates darker and lighter shades that work within our
    system. Al tough it is still possible to change these if the clients wants
    it.
  </p>
  <div class="fade image">
    <img
      src="/images/brick colors.gif"
      alt="The color picker programatically creates lighter and darker shades, to fit our theming"
    />
  </div>
  <label class="fade"
    >The color picker programatically creates lighter and darker shades, to fit
    our theming</label
  >
  <h2 class="fade" id="maklr-verkopen">Maklr. verkopen</h2>
  <p class="fade">
    Maklr. verkopen (previously just maklr.) is a platform to sell new
    construction houses. It reduces the unnecessary time for both real estate
    agents (or developers) and buyers, by digitizing the whole, or part of the,
    application process.
  </p>
  <p class="fade">
    Maklr is a combination between the dutch word for real estate agent
    (makelaar) and the popularly pronunciation of the word easier (makkelijker).
    I came up with the name, as a funny working title, but it stuck around and
    became our brand.
  </p>
  <p class="fade">
    Here is a quick overview of the real estate agent side and how they can add
    more buildnumbers:
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/xee9Z3-Q5as"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="fade">The buyer application flow:</p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/haBjzJIq7ds"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="fade">
    Here is how the real estate agent can manually assign a house, or
    automatically trough our algorithm that optimizes for number of houses sold:
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/8QZC8019i3c"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="fade">
    Here is how the buyer accepts a house and sign the contracts:
  </p>
  <iframe
    width="560"
    height="315"
    class="fade"
    src="https://www.youtube-nocookie.com/embed/WvYKr2WUObw"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <h3 class="fade" id="my-role">My role</h3>
  <p class="fade">
    I was heavily involved in the User research, UX, Design, and Front End
    development. As BRICK is mostly designed around Maklr. verkopen.
  </p>
  <h2 class="fade" id="maklr-websites">Maklr. Websites</h2>
  <p class="fade">
    One of the projects I am currently working on is a website builder for new
    construction projects. The goal is to let real estate agents and developers
    quickly configure a landing page on their own. This will eventually connect
    to maklr. verkopen so it will be able to display all the info of the
    individual houses in the project and lower the barrier to apply for a house.
  </p>
  <p class="fade">Here is a sneak peak of what the CMS looks like:</p>
  <iframe
    width="560"
    height="315"
    src="https://www.youtube-nocookie.com/embed/SBXX-UUYIgI"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <p class="fade">
    Because our team has shrunk quite a bit over the past year, I am basically
    building this on my own.
  </p>
  <h2 class="fade" id="other-works">Other Works</h2>
  <h3 class="fade" id="graphic-design">Graphic design</h3>
  <p class="fade">
    I do all the graphic design and company art, for realtech, and I did some
    logo designs for our sister companies. Here are some examples of my work.
  </p>

  <div class="fade image">
    <img
      src="/images/new illustrations.png"
      alt="Some examples of our current brand illustrations, that I make in Blender
      (3D)"
    />
  </div>
  <label class="fade"
    >Some examples of our current brand illustrations, that I make in Blender
    (3D).
  </label>
  <div class="fade image">
    <img
      src="/images/old illustrations.png"
      alt="Showing some of the basic abilities of our theming"
    />
  </div>
  <label class="fade"
    >Some examples of our old brand illustrations, that I did in Illustrator
    (vector).
  </label>
  <div class="fade image">
    <img
      src="/images/Graphic Design.png"
      alt="Some of the logo, icon and other graphic design work I did for realtech and
      sister companies"
    />
  </div>
  <label class="fade"
    >Some of the logo, icon and other graphic design work I did for realtech and
    sister companies.
  </label>
  <h3 class="fade" id="other-products">Other products</h3>
  <p class="fade">
    During my time at Realtech I have worked on a dozen or so other products,
    most of them never leaving the prototype or MVP phase as we where (and in
    some ways still are) trying to find out what our customers need. If your are
    interested I will gladly talk more about those products, and their
    development processes.
  </p>
</template>
