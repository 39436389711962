<template>
  <h1 class="fade resize">Strengths</h1>
  <p class="fade">
    At my current job I mostly work with Angular, for my personal projects I
    often use Vue.js and I have some experience with react. One of my main
    responsibilities is to build and maintain our design system and component
    library, so I am well versed in HTML and (S)CSS, as well as TypeScript
    (JavaScript) for front-end user interaction/animation and componentization
    purposes.
  </p>

  <p class="fade">
    Altough I prefer to go straight from a wireframe to code, I am also able to
    use UI design software such as Adobe XD, InVision or Figma. I am also quite
    comfortable using vector and raster image programs such as Illustrator and
    Photoshop, as well as 3D moddeling in blender.
  </p>

  <p class="fade">
    Another one of my strengths is understanding complex systems and
    communicating them in a (visually) clear manner.
  </p>
</template>
